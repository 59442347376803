/**
 * React.
 */
import * as React from "react"

/**
 * Async Storage.
 * https://react-native-async-storage.github.io/async-storage/docs/usage
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Formik.
 * https://formik.org/
 * https://formik.org/docs/overview
 */
import { Formik, Form, Field, ErrorMessage } from 'formik';

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Languages.
 * Text for translated page content.
 */
import LangEN from "../languages/LangEN"
import LangFR from "../languages/LangFR"

/**
 * Global variable(s).
 * 
 * Notes:
 * Set custom global variables for dev/prod environments.
 * 
 * Example Var:
 * GATSBY_ENVIRONMENT="Development"
 * 
 * To add/view value(s) in current file(s),
 * Development: {root}/.env.development
 * Production:  {root}/.env.production
 * 
 * To reference the variable(s) in code use,
 * let foo    = process.env.{VARIABLE-NAME1}
 * const blah = process.env.{VARIABLE-NAME2}
 */
const remoteUrl = process.env.GATSBY_REMOTE_URL

const Login = ({ location }) => {

  /* Translate string to chosen language */
  function translate_string( str = '' ) {
    /* Set default language */
    let lang = LangEN
    
    /* Get URL so we can display page with chosen language */
    let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''
    
    /* Switch menu based on language value in url */
    if ( window_url.indexOf( "/fr/" ) > -1 ) {
      lang = LangFR
    }
    
    /* Convert string to language */
    for( var i in lang ) {
      if ( str === i ) {
        return lang[i]
      }
    }
  }

  /* Set default language links */
  let register_url = '/register/'
  let lostpassword_url = '/lost-password/'

  /* Get URL so we can display page with chosen language */
  let window_url = ( typeof window !== 'undefined' ) ? window.location.href : ''
    
  /* Switch menu based on language value in url */
  if ( window_url.indexOf( "/fr/" ) > -1 ) {
    register_url = '/fr/register/'
    lostpassword_url = '/fr/lost-password/'
  }

  /* If url contains a redirect url, redirect the member after login */ 
  const redirectUrl = location.search.split( 'redirect=' );
  const membersUrl = ( redirectUrl['1'] ) ? redirectUrl['1'] : '/members/dashboard/'
  
  /* If new sign in, display success message */
  const params = new URLSearchParams( location.search )
  let newAccount = ( 'new' === params.get( 'signin' ) ) ? translate_string( 'login-new-account-success' ) : '';

  /* Set user to logged in */
  async function setUserLoggedIn( value ) {
    const jsonValue = JSON.stringify( value )
    await AsyncStorage.setItem( "siteVals", jsonValue )
  } 
  
  /* Authenticate user details */
  function userAuth( email, password ) {
    /* Set form variables */
    const authUrl = remoteUrl + '/?rest_route=/simple-jwt-login/v1/auth';
    const data = {
        email: email,
        password: password,
        AUTH_KEY: "THISISMySpeCiaLAUthCode"
    };
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    document.getElementById( "login_status" ).innerHTML = translate_string( 'login-attempt' )
    /* Send the form data to Wordpress */
    fetch( authUrl, requestMetadata )
        .then(res => res.json()) 
        .then(result => {
          /* On response, what should we do? */
          if ( result.success ) {
            /* Login user */
            userLogin( result.data.jwt ) 
          } else {
            /* Update user login status */
            setUserLoggedIn( { userLoggedIn: false } ) 
            /* Show notice and reset form */
            document.getElementById( "login_status" ).innerHTML = translate_string( 'login-incorrect-details' )
            document.getElementById( "email" ).value = '';
            document.getElementById( "password" ).value = '';
            document.getElementById( "login_submit" ).disabled = false;
          }
        });
  }

  /* Set user to logged in */
  function userLogin( JWT_token ) {
    /* Set form variables */
    const data = {
      JWT: JWT_token, 
    }; 
    /* Send the form data to Wordpress */
    fetch( remoteUrl + `/?rest_route=/simple-jwt-login/v1/autologin&JWT=${encodeURIComponent(data.JWT)}`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
        },
    })
    .then(res => res.json())
    .then(result => {
      /* On response, what should we do? */
      if ( result.success ) {
        /* Get user details */
        let JWT_decode = JSON.parse( atob( JWT_token.split('.')[1] ) )
        /* Set user as logged in */
        setUserLoggedIn( { 
          userLoggedIn: true, 
          userName: JWT_decode.username,
          userId: JWT_decode.id,
          userEmail: JWT_decode.email
        } )
        /* Redirect to url */
        document.location = membersUrl;
      } else {
        /* Show notice and reset form */
        document.getElementById( "login_status" ).innerHTML = translate_string( 'login-error' )
        document.getElementById( "email" ).value = '';
        document.getElementById( "password" ).value = '';
        document.getElementById( "login_submit" ).disabled = false;
      }
    });
  }

  return (
  <Layout>
  {/* SEO details */}
  <Seo title={translate_string( 'login-seo-title' )} />
  <div>
      <Formik
      /* Set initial values */
      initialValues={{ 
          email: '', 
          password: '', 
      }}
      validate={values => {
          const errors = {};
          /* Set errors for field values */ 
          if (!values.email) {
              errors.email = translate_string( 'login-email-required' )
          } else if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address.';
          }
          if (!values.password) {
              errors.password = translate_string( 'login-password-required' )
          }              
          return errors;
      }}

      /* On form submit */
      onSubmit={(values) => {
          setTimeout(() => {
              /* Set form variables */
              let form_values = JSON.stringify( values, null, 2 )
              let jsonObj = JSON.parse( form_values );
              /* Authenticate user */
              userAuth( jsonObj.email, jsonObj.password )
          }, 400);
      }}
      >
      {({ isSubmitting }) => (
          <Form>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 p-1 text-center">

                    {/* Form details */} 
                    <h1 id="login_title">
                      {translate_string( 'login-signin-title' )}
                    </h1>

                    {/* Form response when submitted */}
                    <div id='login_status'>
                      <div dangerouslySetInnerHTML={{ __html: newAccount }} />
                    </div>

                  </div>
                </div>

                {/* Form fields */}
                <div id="login_form_fields" className="col-md-12 p-1 text-center">

                    {/* Form field - email */}
                    <div className="pb-2">
                        <Field id="email" type="email" name="email" placeholder={translate_string( 'login-email-placeholder' )} className="login-field" autoComplete="username" />
                        <ErrorMessage name="email" component="div" className="login-field-error d-flex justify-content-center" />
                    </div>

                    {/* Form field - password */}
                    <div className="pb-2">
                        <Field id="password" type="password" name="password" placeholder={translate_string( 'login-password-placeholder' )} className="login-field" autoComplete="current-password" />
                        <ErrorMessage name="password" component="div" className="login-field-error d-flex justify-content-center" />
                    </div> 

                    {/* Form submit button */}            
                    <div>
                        <button id="login_submit" 
                                type="submit" 
                                disabled={isSubmitting} 
                                className="btn btn-outline-success"
                                style={{width: '280px'}}>
                          {translate_string( 'login-signin' )}
                        </button>
                    </div>

                    {/* Url to Register Account / Lost Password */} 
                    <div>
                      <br />
                      <a href={register_url}>{translate_string( 'register-link' )}</a> | <a href={lostpassword_url}>{translate_string( 'lostpassword-link' )}</a>
                    </div>

                </div>
              </div>
          </Form>
        )}
        </Formik>
    </div>
  </Layout>
  )
}

export default Login
